<template>
<div div class="mobile-page-layout">
    <div class="mobile-page-header">
        <div class="mobile-page-header-row">
            <div class="mobile-page-header-right">
   
                <router-link :to="{ name: 'Dashboards'}" class="mobile-page-header-icon pointer back-icon">
                    <i class="header-uil-icon uil uil-angle-right-b"></i>
                </router-link>

                <div class="mobile-page-header-icon">
                    <svg class="header-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">viewBox="0 0 512 512">
                        <path class="fa-primary" d="M408 360H472C485.3 360 496 370.7 496 384C496 397.3 485.3 408 472 408H408V472C408 485.3 397.3 496 384 496C370.7 496 360 485.3 360 472V408H296C282.7 408 272 397.3 272 384C272 370.7 282.7 360 296 360H360V296C360 282.7 370.7 272 384 272C397.3 272 408 282.7 408 296V360z" />
                        <path class="fa-secondary" d="M32 80C32 53.49 53.49 32 80 32H176C202.5 32 224 53.49 224 80V176C224 202.5 202.5 224 176 224H80C53.49 224 32 202.5 32 176V80zM32 336C32 309.5 53.49 288 80 288H176C202.5 288 224 309.5 224 336V432C224 458.5 202.5 480 176 480H80C53.49 480 32 458.5 32 432V336zM432 32C458.5 32 480 53.49 480 80V176C480 202.5 458.5 224 432 224H336C309.5 224 288 202.5 288 176V80C288 53.49 309.5 32 336 32H432z" />
                    </svg>
                </div>
                <div class="mobile-page-header-text">{{ dashboard.title }}</div>  
            </div>
            <div class="mobile-page-header-actions">
                <router-link v-if="!editable" class="mobile-page-header-icon pointer link-icon" :to="{ name: 'DashboardEdit', params: { id : dashboard.id }, }">
                    <i class="header-uil-icon uil uil-setting"></i>
                </router-link>

                <div class="mobile-page-header-menu">
                    <template>
                        <div class="text-center">
                            <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="mobile-page-header-icon pointerv margin-0" v-bind="attrs" v-on="on">
                                    <i class="header-material-icon mdi mdi-dots-vertical"></i>
                                </div>
                            </template>
                            <v-list>
                                <v-list-item v-for="(item, index) in menus" :key="index"  @click.prevent="get_modal(item.modal)">
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                            </v-menu>
                        </div>
                    </template>                 
                </div>
            </div>
        </div>
    </div>

    <slot v-if="loading">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </slot>

    <slot v-else>
        <template v-if="widgets.length">
            <div class="row tgju-widgets-row dashboard-widget-list widget-border-fix" :class="{ 'dashboard-page-loader': loading }">
                <template v-for="item in widgets">
                    <li class="tgju-widgets-block col-12 mb-m-2 draggable-element" :class="item.sizeClass" :key="item.widget.id" :data-widget-type="item.widget.type" :data-widget-id="item.widget.id" :data-widget-size="item.widget.size">
                        <div class="tgju-widget light">
                            <div class="list-widget  list-row bold list-row-title">
                                <div class="list-col l-title dot head-color no-border">
                                    <span>{{ item.widget.title }}</span>
                                </div>
                            </div>
                            <div class="tgju-widget-content full-padding">
                                <div class="tables-default normal overflow-unset dashboard-widget-min">
                                    <component v-bind:is="item.component" :full="true" :widget="item.widget" :data="item.data" :ref="`widget_${item.widget.id}`" wrapper="dashboard"></component>
                                </div>
                            </div>
                        </div>
                    </li>
                </template>
            </div>
        </template>
        <template v-else>
            <!-- در صورتی که ویجتی اد نشده بود این بخش نمایش باید داده شود -->
                    <div class="noWidget noContent">
                        <div>
                            <i class="uil uil-layer-group-slash noContent-icon" aria-hidden="true"></i>
                            <p class="noContent-title">در حال حاضر ویجتی برای نمایش وجود ندارد!</p>
                            <p class="noContent-description">
                                شما می توانید با کلیک بر روی آیکن چرخ‌دنده در بالای همین صفحه به ویرایش این داشبورد رفته و ویجت مد نظر خود را اضافه نمایید .
                            </p>
                        </div>
                    </div>
        </template>
    </slot>
</div>
</template>

<script>
// از این ویو برای نمایش اطلاعات داشبورد استفاده میشود
// و ویجت های آن داشبورد استفاده میشود
import draggable from 'vuedraggable'

export default {
    name: 'Dashboard',
    components: {
        draggable
    },
    data: function () {
        return {
            dashboard: {
                id: null,
                user_id: null,
                title: '',
                type: null,
                default: false,
                created_at: null,
            },
            widgets: [],
            loading: true,
            editable: false,
            menus: [
                { title: 'راهنما', modal: 'help' }
            ],
        }
    },

    mounted() {
        if (typeof this.$router.currentRoute.params.id != 'undefined') {
            this.dashboard.id = this.$router.currentRoute.params.id;
        }

        this.loadDashboard();

        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
    },

    methods: {
        // لود کردن داشبورد
        // در لیست داشبورد هایی که در ستور ویو جی اس قرار دارند پیمایش میکنیم تا آی دی مورد نظر را بگیریم
        // به اضافه ی اطلاعات آن
        loadDashboard() {
            let dashboards = this.$store.state.dashboards;

            dashboards.forEach(dashboard => {
                if (this.dashboard.id && dashboard.id == this.dashboard.id) {
                    this.dashboard = dashboard;
                } else if (!this.dashboard.id && dashboard.default) {
                    this.dashboard = dashboard;
                }
            });

            if (!this.dashboard.id && dashboards) {
                this.dashboard = dashboards[0];
            }

            if (this.dashboard.id) {
                this.loadWidets();
            }
        },
        // لود کردن ویجت های داشبورد مورد نظر
        loadWidets() {
            this.$helpers.getWidgets({ dashboard_id: this.dashboard.id }).then((widgets) => {
                widgets.forEach((item, index) => {
                    if (!item.component) {
                        widgets.splice(index, 1);
                        return;
                    }

                    Vue.component(
                        item.component,
                        () => import(`@/components/${item.component}`)
                    );
                });

                this.widgets = widgets;
                this.loading = false;
            });
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Dashboards.vue',
                title: 'داشبورد‌ها',
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },

    }
}
</script>
